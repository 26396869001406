(function($) {
    'use strict';
 /**
     * Add a 'Back' link to sub menus
     */
    /* $('.sub-menu').each(function() {
        $(this).prepend('<li class="menu__item menu__item--back">Back</li>');
    });*

    $('.menu__item--back').on('click', function() {
        $(this).closest('.is-active').removeClass('is-active');
        $(this).parents('nav').removeClass('is-active');
    });/

    /**
     * Register click events on sub navigation toggles
     */

    $('.menu__item--has-children > a').each(function() {
        $(this).after('<span class="submenu_action">+</span>');
    });

    $('.menu__item--has-children .submenu_action').on('click', function(e) {
        e.preventDefault();

        //$('.c-nav-primary').addClass('is-active');

        var parent = $(this).parent(),
            menu = $(this).closest('ul');

        menu.find('.submenu_action').text('+');

        if ( parent.hasClass('is-active') ) {
            menu.find('.menu__item').removeClass('is-active');
            menu.find('.submenu_action').text('+');
        } else {
            menu.find('.menu__item').removeClass('is-active');

            parent.addClass('is-active');
            parent.find('.submenu_action').text('-');
        }
    });


})(jQuery);